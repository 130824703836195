body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Lemon/Milk';
  font-weight: normal;
  src: url('../fonts/LemonMilk.woff');
}

@font-face {
  font-family: 'Lemon/Milk';
  font-weight: bold;
  src: url('../fonts/LemonMilkbold.woff');
}

@font-face {
  font-family: 'Lemon/Milk';
  font-weight: 200;
  src: url('../fonts/LemonMilklight.woff');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: normal;
  src: local('Source Sans Pro'), url('../fonts/SourceSansPro-Regular.otf.woff') format('woff');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: bold;
  src: local('Source Sans Pro Bold'), url('../fonts/SourceSansPro-Bold.otf.woff') format('woff');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 500;
  src: local('Source Sans Pro Semi-Bold'), url('../fonts/SourceSansPro-Semibold.otf.woff') format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: normal;
  src: local('Raleway'), url('../fonts/raleway-regular.woff') format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: bold;
  src: local('Raleway Bold'), url('../fonts/raleway-bold.woff') format('woff');
}

.print-page-break {
  margin-top: 50px;
}

.print-footer {
  display: inline;
}

.page-outline {
  box-shadow: 0 0 17px 10px rgba(0,0,0,0.1);
}

@media print {
  @page {
    size: 794px 990px!important;
    margin: 5px 0 0 !important;
    /*size: portrait!important;*/
  }
  .print-page-break {
    margin-top: 0!important;
    margin-bottom: 0!important;
    box-shadow: none!important;
    page-break-after: always!important;
    page-break-inside: avoid!important
  }
  .print-footer {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .page-outline {
    box-shadow: none;
  }
  a {
    position: relative;
    z-index: 1;
  }
  @-moz-document url-prefix() {
    .print-page-break {
      overflow: visible!important;
      border: 1px solid #fff!important
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.backdrop-loading-container {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  opacity: 0.5;
  z-index: 5000
}

.backdrop-loading-container-darker {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  opacity: 0.7;
  z-index: 5000
}

.ExcelTable2007 {
  border: 1px solid #B0CBEF;
  border-width: 1px 0px 0px 1px;
  font-size: 11pt;
  font-family: Calibri;
  font-weight: 100;
  border-spacing: 0px;
  border-collapse: collapse;
  padding: 10px;
}

.ExcelTable2007 TH {
  background: #9EB6CE;
  font-weight: bold;
  font-size: 14px;
  border: 1px solid #6b7888;
  border-width: 0px 1px 1px 0px;
  height: 17px;
  line-height: 17px;
}

.ExcelTable2007 TD {
  border: 0px;
  background-color: white;
  padding: 0px 4px 0px 2px;
  border: 1px solid #D0D7E5;
  border-width: 0px 1px 1px 0px;
}

.ExcelTable2007 TD span {
  display: inline-block;
  background: #91c1f5;
  margin: 2px 4px;
  padding: 2px 4px;
  border-radius: 10px;
  white-space: nowrap;
}

.meta-data-table-array-span {
  display: inline-block;
  background: #91c1f5;
  margin: 2px 4px;
  padding: 2px 4px;
  border-radius: 10px;
  white-space: nowrap;
}

.meta-data-table-entity-link {
  color: #0c5db7 !important;
  text-underline: #007bff;
  text-decoration: underline;
  cursor: pointer;
}


.ExcelTable2007 TD.heading {
  background: #9EB6CE;
  font-weight: bold;
  font-size: 14px;
  border: 1px solid #6b7888;
}

.ExcelTable2007 TD B {
  border: 0px;
  background-color: white;
  font-weight: bold;
}

.ExcelTable2007 TD.heading {
  background-color: #E4ECF7;
  text-align: center;
  border: 1px solid #9EB6CE;
  border-width: 0px 1px 1px 0px;
  font-weight: bold;
}

.dataTables_wrapper td::-webkit-scrollbar {
  display: none;
}

.dataTables_wrapper td{
  /*min-width: 20vw;*/
  max-width: 12vw;
  overflow-y: scroll;
}

.table-container-pull-left {
  /*display: inline-block;*/
  /*float: left;*/
}


.meta-data-table-action-container {
  display: flex;
  width: auto;
}

.meta-data-table-action-btn {
  margin: 4px 4px;
  cursor: pointer;
}

.meta-data-table-action-btn:hover {
  transform: scale(1.4, 1.4);
  transition: transform 200ms ease-out;
}

.level3-uic-color-picker-btn {
  background: #4285F4;
  color: #fff;
  width: 100%;
  border: transparent;
  font-size: 1.1rem;
  padding: 5px 8px;
  border-radius: 5px;
}

.level3-uic-color-picker-btn:hover {
  opacity: 0.8;
  transition: opacity 200ms ease-out;
}
